import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PROJECT_FOLDER_PATH } from 'constants/app';
import auth from 'utils/auth';

/**
 * Hooks to redirect user if they have been logged in
 */
function usePublicProtectedRoute() {
  const router = useRouter();
  const [loggedIn, setLoggedIn] = useState(false);

  const checkCredential = () => {
    const parsedCookie = auth.getToken();

    if (localStorage.at && parsedCookie) {
      const {
        accessToken: { token },
      } = parsedCookie;

      return !!token;
    }

    return false;
  };

  useEffect(() => {
    const isLoggedIn = checkCredential();
    setLoggedIn(isLoggedIn);

    if (isLoggedIn && !router.asPath.includes('/stream')) {
      window.location.href = `${window.location.origin}${PROJECT_FOLDER_PATH}/stream`;
    }
  }, [router]);

  return loggedIn;
}

export default usePublicProtectedRoute;
