
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import Head from 'next/head';
import usePublicProtectedRoute from 'hooks/usePublicProtectedRoute';
import { assetPrefix } from 'constants/app';
import HomePage from 'features/home-page';

const Index = () => {
  // eslint-disable-next-line no-unused-vars
  const loggedIn = usePublicProtectedRoute();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://www.google.com" />
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link
          rel="preload"
          href={`${assetPrefix}/font/ProximaNova-Regular.woff`}
          as="font"
        />
      </Head>
      <HomePage />
    </>
  );
};

export default Index;
